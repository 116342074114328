<template>
    <div>
        <b-form @submit="onSubmit" @reset="onReset">
            <b-row>
                <b-col>
                    <b-form-group label="Start Date:" label-class="font-weight-bold pt-0">
                        <b-form-datepicker v-model="startDate"></b-form-datepicker>
                    </b-form-group>
                    <b-form-group label="End Date:" label-class="font-weight-bold pt-0">
                        <b-form-datepicker v-model="endDate"></b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Candidates:" label-class="font-weight-bold pt-0">
                        <b-list-group>
                            <b-list-group-item>
                                <b-form-checkbox v-model="selectAll" @change="toggleAll">
                                    Select/Unselect All Candidates
                                </b-form-checkbox>
                            </b-list-group-item>
                            <b-list-group-item v-for="candidate in candidates" :key="candidate.userId">
                                <b-form-checkbox v-model="selectedCandidates" :value="candidate.userId">
                                    {{ candidate.name }}
                                </b-form-checkbox>
                            </b-list-group-item>
                        </b-list-group>
                    </b-form-group>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-button v-if="exporting" type="button" variant="primary" disabled>
                <b-spinner small></b-spinner>
                <span> Downloading...</span></b-button>
            <b-button v-else type="submit" variant="primary">
                Download</b-button>

            <b-button type="reset" variant="secondary" class="ml-2">Reset</b-button>
        </b-form>
    </div>
</template>

<script>
import Repository from "@/repositories/RepositoryFactory";
const ExportRepository = Repository.get("ExportRepository");
export default {
    name: "DownloadEndorsements",
    data() {
        return {
            startDate: "",
            endDate: "",
            loading: false,
            errorMessage: "",
            exporting: false,
            candidates: [],
            selectedCandidates: [],
            selectAll: false,
        };
    },
    computed: {},
    async mounted() {
        this.candidates = await this.$store.dispatch(
            "candidates/getAllCandidates",
            null
        );
        this.candidates = this.candidates.filter((x) => x.name);
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault();
            if (this.selectedCandidates.length == 0) {
                this.errorMessage = "Please select candidate(s).";
                return;
            }
            this.errorMessage = "";
            this.exporting = true;
            setTimeout(async () => {
                await ExportRepository.ExportEndorsements(
                    this.selectedCandidates.join(),
                    this.startDate,
                    this.endDate
                );
                this.exporting = false;
            }, 500);
        },
        onReset() {
            this.startDate = "";
            this.endDate = "";
            this.selectedCandidates = [];
        },
        toggleAll(checked) {
            this.selectedCandidates = checked
                ? this.candidates.map((x) => x.userId).slice()
                : [];
        },
    },
};
</script>
